import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const title = "Not Found"

const NotFoundPage = () => (
  <Layout title={title}>
    <SEO title={title} />
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;

        @media (min-width: ${props => props.theme.screen.tablet}) {
          min-height: 500px;
        }
      `}
    >
      <p>Oops.</p>
      <p
        css={`
          text-align: center;
        `}
      >
        Looks like we couldn't find the page you were looking for.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
